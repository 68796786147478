import { Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick';

import MYR from '../../../assets/images/currencies/myr.png';
import THB from '../../../assets/images/currencies/thb.png';
import IDR from '../../../assets/images/currencies/idr.png';
import VND from '../../../assets/images/currencies/vnd.png'
import KRW from '../../../assets/images/currencies/krw.png';
import BRL from '../../../assets/images/currencies/brl.png';
import BDT from '../../../assets/images/currencies/bdt.png';
import CryptoImg from '../../../assets/images/crypto.png';
import IphoneImg from '../../../assets/images/iphone.png';
import MacbookImg from '../../../assets/images/macbook.png';
import YellowMask from '../../../assets/images/mask-yellow.png';
import WhiteMask from '../../../assets/images/mask-white.png';
import './Service.css';
import ServiceDemoModal from './Modal/ServiceDemoModal';
const Service = ({t}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [demoModal, setDemoModal] = useState({ visible: false, content: '', key: 0});
    const laptopSlider = useRef(null);
    const phoneSlider = useRef(null);
    const slideCurrencies = [
        [MYR, THB, IDR, VND],
        [MYR, THB, IDR, VND, KRW, BRL],
        [MYR, THB, IDR, VND, BDT],
        [CryptoImg]
      ];


    const slideDetails = [
    {
        title: `${t.onlineBankingTitle}`,
        subtitle: `${t.onlineBankingSubtitle}`
    },
    {
        title: `${t.bankTransferTitle}`,
        subtitle: `${t.bankTransferSubtitle}`
    },
    {
        title: `${t.eWalletTitle}`,
        subtitle: `${t.eWalletSubtitle}`
    },
    {
        title: `${t.cryptoTitle}`,
        subtitle: `${t.cryptoSubtitle}`
    }
    ];  

    
  const laptopSliderImages = [
    require('../../../assets/images/laptop-slide-1.png'),
    require('../../../assets/images/laptop-slide-2.png'),
    require('../../../assets/images/laptop-slide-3.png'),
    require('../../../assets/images/laptop-slide-4.png'),
  ];

  const phoneSliderImages = [
    require('../../../assets/images/phone-slide-1.png'),
    require('../../../assets/images/phone-slide-2.png'),
    require('../../../assets/images/phone-slide-3.png'),
    require('../../../assets/images/phone-slide-4.png'),
  ];

    const laptopSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: (i) => (
          <div className="dot"></div>
        ),
        beforeChange: (oldIndex, newIndex) => {
          setCurrentSlide(newIndex);
        }
      };
    
    const phoneSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlide(newIndex);
        }
    };
      
    useEffect(() => {
        laptopSlider.current.slickGoTo(currentSlide);
        phoneSlider.current.slickGoTo(currentSlide);
      }, [currentSlide]);

  const showDemo = () => {
    const demoGuides = ['online-banking', 'transfer', 'e-wallet', 'crypto'];
    const timestamp = Date.now();
    setDemoModal({visible: true, content: demoGuides[currentSlide], key: timestamp});
  }

  const closeDemo = () => {
    setDemoModal({visible: false, content: ''});
  }

  return (
    <Row id="service" className="contents">
        <Row className={`currency-container ${currentSlide === 1 ? 'currency-container-2' : ''}`}>
          {slideCurrencies[currentSlide].map((currencyImg, index, arr) => (
            <img key={index} src={currencyImg} alt='currency_logo' className={currentSlide === 1 && (index === arr.length - 2) ? 'currency-logo' : ''} />
          ))}
        </Row>

        <div className="title-content">
          <div className="title-1">{slideDetails[currentSlide].title}</div>
          <div className="subtitle-1">{slideDetails[currentSlide].subtitle}</div>
        </div>
        <div className="new-devices-container">
          <div className="new-laptop">
            <img src={MacbookImg} alt="laptop_display"/>
              <div className="screen-content">
                <Slider ref={laptopSlider} {...laptopSettings}>
                    {laptopSliderImages.map((img, index) => (
                        <img key={index} src={img} alt={`slide-${index}`} />
                    ))}
                </Slider>
              </div>
          </div>
          <div className="new-phone">
            <img src={IphoneImg} alt="phone_display" className='phone-img'/>
            <div className="screen-content">
              {currentSlide === 0 ? (<img src={YellowMask} alt='mask' className='mask' />) : <img src={WhiteMask} alt='mask' className='mask' />}
            </div>
            <div className="screen-content">
              <Slider ref={phoneSlider} {...phoneSettings}>
                  {phoneSliderImages.map((img, index) => (
                      <img key={index} src={img} alt={`slide-${index}`} className={`phone-slider-img slide-${index}`} />
                  ))}
              </Slider>
            </div>
          </div>
        </div>
        <button className='demo-btn' onClick={showDemo}>{t.demo}</button>

        <ServiceDemoModal visible={demoModal.visible} content={demoModal.content} onCancel={closeDemo} key={demoModal.key} t={t}/>
    </Row>
  )
}

export default Service