import { Col, Row } from 'antd'
import React, { useRef, useState } from 'react'
import axios from 'axios';

import AppLogo from '../../../assets/images/logo.png';
import './Contact.css';

const Contact = ({t}) => {
    // const APP_URL = process.env.REACT_APP_APP_URL;

    const [loading, setLoading] = useState(false);
    const [resMessage, setResMessage] = useState('');
    const [shouldDisplay, setShouldDisplay] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageType, setMessageType] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company_name: '',
        company_url: '',
        type: '',
        contact_details: '',
        message: ''
      });

    const formRef = useRef(null);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
          setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
      };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setResMessage('');
        // const headers = {
        //   'Content-Type': 'text/plain'
        // };

        try {
          const response = await axios.post(`https://admin.ggpay.io/api/saveContactForm`, formData);
          if(response.status === 200) {
            setMessageType('success');
            setResMessage(response.data.message);
            setShouldDisplay(true);
            if (formRef.current) {
              formRef.current.reset();
            }
    
            setTimeout(() => {
              setShowMessage(true);
            }, 100);
    
            setTimeout(() => {
              setShowMessage(false);
            }, 3000);
    
            setTimeout(() => {
              setShouldDisplay(false);
            }, 3500);
          }
          else {
            setMessageType('error');
            setResMessage("Error submitting the form");
          }
        }
        catch(error) {
          setMessageType('error');
          setResMessage("There was an error submitting the form");
        }
        finally {
          setLoading(false);
        }
      };

  return (
    <Row id="contact" className="contents" justify="center" align='middle'>
        <Col className="contact-header" span={24}>
          <div className="title-5">{t.section5Title}</div>
        </Col>
        <Col className="contact-header">
          <div className="contact-direpay-logo">
            <img src={AppLogo} alt='direpay_logo'/>
          </div>
        </Col>
        <Col span={24} style={{marginBottom: '5%'}}>
            <form className="section-form" onSubmit={handleSubmit} ref={formRef}>
            <input type="text" name="name" placeholder={t.nameInput} onChange={handleInputChange} autoComplete='off' required />
            <input type="email" name="email" placeholder={t.emailInput} onChange={handleInputChange} autoComplete="off" required />
            <input type="text" name="company_name" placeholder={t.companyNameInput} onChange={handleInputChange} autoComplete="off" required />
            <input type="text" name="company_url" placeholder={t.companyWebsiteInput} onChange={handleInputChange} autoComplete="off" required />
            <select name="type" defaultValue="" placeholder={t.contactTypeInput} onChange={handleInputChange} required className='contact-select'>
                <option value="" disabled>{t.contactTypeInput}</option>
                <option value="email">Email</option>
                <option value="skype">Skype</option>
                <option value="telegram">Telegram</option>
            </select>
            <input type="text" name="contact_details" placeholder={t.contactDetailsInput} onChange={handleInputChange} autoComplete="off" required/>
            <textarea name="message" placeholder={t.contactMsg} onChange={handleInputChange} autoComplete="off" required></textarea>
            <button type='submit' disabled={loading}>{loading ? <div className="spinner"></div> : t.contactBtn}</button>
            </form>
        </Col>
      {shouldDisplay && <div className={`message-box ${showMessage ? 'show' : ''}`}>
        {resMessage && <div className={`response-message ${messageType}`}>{resMessage}</div>}
      </div>}
    </Row>
  )
}

export default Contact