import React, { useEffect, useState } from 'react'
import Banner1 from './../../../../../assets/images/service-demo/crypto-1.svg'
import Banner2 from './../../../../../assets/images/service-demo/crypto-2.svg'
import Joyride from 'react-joyride';
import './ServiceGuide.css'
const GuideCrypto = ({t, handleJoyrideCallback, handleImageLoad, currentStep}) => {
  const [run, setRun] = useState(false);
  const [banner, setBanner] = useState(Banner1);

  useEffect(() => {
    setTimeout(() => {
      setRun(true)
    }, 500)
  })

  useEffect(() => {
    if (currentStep >= 4) {
      setBanner(Banner2);
    } else {
      setBanner(Banner1);
    }
  }, [currentStep])

  const stepSettings = {
    disableBeacon: true,
    spotlightPadding: 0,
    floaterProps: {
      disableAnimation: true,
    },
    locale : {
      skip: t.guide.skip,
      back: t.guide.back,
      next: t.guide.next,
      last: t.guide.finish
    }
  }

  const steps = [
    {
      title: t.guide.crypto.page1,
      content: <div>1. {t.guide.crypto.step1}</div>,
      target: '.focus-step-crypto-0',
      ...stepSettings
    },
    {
      title: t.guide.crypto.page1,
      content: <div>2. {t.guide.crypto.step2}</div>,
      target: '.focus-step-crypto-1',
      ...stepSettings
    },
    {
      title: t.guide.crypto.page1,
      content: <div>3. {t.guide.crypto.step3}</div>,
      target: '.focus-step-crypto-2',
      ...stepSettings
    },
    {
      title: t.guide.crypto.page1,
      content: <div>4. {t.guide.crypto.step4}</div>,
      target: '.focus-step-crypto-3',
      ...stepSettings
    },
    {
      title: t.guide.crypto.page2,
      content: <div>5. {t.guide.crypto.step5}</div>,
      target: '.focus-step-crypto-4',
      ...stepSettings
    },
    {
      title: t.guide.crypto.page2,
      content: <div>6. {t.guide.crypto.step6}</div>,
      target: '.focus-step-crypto-5',
      ...stepSettings
    },
    {
      content: <div>7. {t.guide.crypto.step7}</div>,
      target: '.focus-step-crypto-6',
      placement: 'center',
      ...stepSettings
    },
    {
      content: <div>8. {t.guide.crypto.step8}</div>,
      target: '.focus-step-crypto-7',
      placement: 'center',
      ...stepSettings
    },
  ]

  return (
    <div className='container'>
      <Joyride
        callback={handleJoyrideCallback}
        steps={steps}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <img className='banner' src={banner} onLoad={handleImageLoad} />
      {Array.from({ length: 8 }).map((_, index) => (
        <div className={`focus-step-crypto-${index}`} key={index}></div>
      ))}
    </div>
  );
  
}

export default GuideCrypto