import React from 'react'
import { Row } from 'antd';

import Section4Icon from '../../../assets/images/section-4.png';
import './WhyUs.css';

const WhyUs = ({t}) => {
  return (
    <Row id='whyus' className="contents" justify="space-between" align='middle' >
        <div className="desc-container">
          <h1>{t.section4Title}</h1>
        </div>
        <div className="content">
          <div className="card">
            <div className="card-icon"><img src={Section4Icon} alt='section_icon'/></div>
            <div className="card-title">{t.cardTitle1}</div>
            <div className="card-description">{t.cardSubtitle1}</div>
          </div>
          <div className="card">
            <div className="card-icon"><img src={Section4Icon} alt='section_icon'/></div>
            <div className="card-title">{t.cardTitle2}</div>
            <div className="card-description">{t.cardSubtitle2}</div>
          </div>
          <div className="card">
            <div className="card-icon"><img src={Section4Icon} alt='section_icon'/></div>
            <div className="card-title">{t.cardTitle3}</div>
            <div className="card-description">{t.cardSubtitle3}</div>
          </div>
          <div className="card">
            <div className="card-icon"><img src={Section4Icon} alt='section_icon'/></div>
            <div className="card-title">{t.cardTitle4}</div>
            <div className="card-description">{t.cardSubtitle4}</div>
          </div>
        </div>
      </Row>
  )
}

export default WhyUs