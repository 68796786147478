import React, { useState, useEffect } from 'react'
import { Anchor, Layout as AntdLayout, Button, Col, Drawer, Row} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import AppLogo from '../../assets/images/logo.png';
import './Topbar.css';
import { useTranslation } from '../../TranslationContext';
const { Header } = AntdLayout;
const { Link } = Anchor;

const Topbar = ({ t }) => {

  let debounceTimer;
  let accumulatedDelta = 0;
  let lastKnownScrollPosition = 0;
  let ticking = false;

  const [visible, setVisible] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState('en');
  const { setLanguage } = useTranslation();
  const [activeLink, setActiveLink] = useState('#home');
  const sections = ["#home", "#service", "#portfolio", "#portfolio-2", "#whyus", "#contact", "#footer"];
  const [isScrolling, setIsScrolling] = useState(false);
  const [lastScrollTime, setLastScrollTime] = useState(0);
  const scrollCooldownPeriod = 1000;

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    setActiveLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
};


  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const DrawerTitle = () => (<Row justify="space-between" align="middle"><img src={AppLogo} alt="app_logo" className='app-logo'/> <CloseOutlined onClick={onClose}/></Row>);

  const handleLinkChange = (link) => {
    setActiveLink(link);
  };

  const handleScroll = (e) => {
    e.preventDefault();
    accumulatedDelta += e.deltaY;
    lastKnownScrollPosition = window.scrollY;
    const direction = e.deltaY > 0 ? 'down' : 'up';

    const now = Date.now();
    const scrollThreshold = 10;

    if (now - lastScrollTime < scrollCooldownPeriod) {
      return;
    }

    if (Math.abs(e.deltaY) < scrollThreshold) {
      return;
    }

    if (!ticking) {
      window.requestAnimationFrame(() => {
        let currentSectionIndex = -1;
        let smallestDistanceToViewportCenter = Infinity;
        sections.forEach((section, index) => {
            const sectionElement = document.querySelector(section);
            if (sectionElement) {
                const rect = sectionElement.getBoundingClientRect();
                const distanceToViewportCenter = Math.abs(rect.top + rect.height / 2 - window.innerHeight / 2);
                if (distanceToViewportCenter < smallestDistanceToViewportCenter) {
                    smallestDistanceToViewportCenter = distanceToViewportCenter;
                    currentSectionIndex = index;
                }
            }
        });
  
        let nextSectionIndex = currentSectionIndex;
        if (direction === 'down' && currentSectionIndex < sections.length - 1) {
            nextSectionIndex = currentSectionIndex + 1;
        } else if (direction === 'up' && currentSectionIndex > 0) {
            nextSectionIndex = currentSectionIndex - 1;
        }

  
        if (nextSectionIndex !== -1 && nextSectionIndex !== currentSectionIndex) {
          setIsScrolling(true);
          setLastScrollTime(now);
          const nextSection = sections[nextSectionIndex];
          document.querySelector(nextSection).scrollIntoView({ behavior: 'smooth' });
          setActiveLink(nextSection);

          setTimeout(() => {
            setIsScrolling(false);
          }, scrollCooldownPeriod); 
        }
        ticking = false;
      });
      ticking = true;
    }
  };

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [activeLink, sections]);

  // const handleScroll = (e) => {
  //   e.preventDefault();

  //   if (debounceTimer) clearTimeout(debounceTimer);

  //   accumulatedDelta += e.deltaY;

  //   debounceTimer = setTimeout(() => {
  //     if (Math.abs(accumulatedDelta) >= 50) {

  //       const currentIndex = sections.indexOf(activeLink);
  //       let nextIndex = currentIndex + (accumulatedDelta > 0 ? 1 : -1);

  //       const viewportHeight = window.innerHeight;
  //       const halfViewportHeight = viewportHeight / 2;
  //       let closestSection = '';
  //       let smallestDistance = Infinity;

  //       sections.forEach(section => {
  //         const sectionEl = document.querySelector(section);
  //         if (sectionEl) {
  //           const rect = sectionEl.getBoundingClientRect();
  //           const distance = Math.abs(rect.top + rect.height / 2 - halfViewportHeight);
  //           if (distance < smallestDistance) {
  //             smallestDistance = distance;
  //             closestSection = section;
  //           }
  //         }
  //       });

  //       if (closestSection === '#portfolio-2' && accumulatedDelta < 0) {
  //         if (sections[currentIndex - 1] !== '#portfolio') {
  //           nextIndex = currentIndex;
  //         }
  //         else {
  //           nextIndex = currentIndex - 1;
  //         }
  //       }

  //       accumulatedDelta = 0;

  //       if (nextIndex >= 0 && nextIndex < sections.length) {
  //         const nextSection = sections[nextIndex];
  //         document.querySelector(nextSection).scrollIntoView({ behavior: 'smooth' });
  //         setActiveLink(nextSection);
  //       }
  //     }
  //   }, 50);
  // };

  // useEffect(() => {
  //   window.addEventListener('wheel', handleScroll, { passive: false });

  //   return () => {
  //     window.removeEventListener('wheel', handleScroll);
  //     if (debounceTimer) clearTimeout(debounceTimer);
  //   };
  // }, [activeLink, sections]);

  return (
    <Header id="header">
            
        <Col className="logo-col"><img src={AppLogo} alt="app_logo" className='app-logo'/></Col>
        <Col className="desktop">
            <Anchor 
            direction="horizontal" 
            getContainer={() => document.getElementById("anchor-content")}
            onChange={handleLinkChange}>
                <Link href="#home" title={t.home} />
                <Link href="#service" title={t.services} />
                <Link href="#portfolio" title={t.portfolio} />
                <Link href="#whyus" title={t.advantage} />
                <Link href="#contact" title={t.contact} />
                <Row justify='center' align="middle" style={{marginLeft: '15px'}}>
                    <Button type="icon" href='#language-en' className={`lang-link ${activeLanguage === 'en' ? 'lang-active-link' : ''}`} onClick={() => handleLanguageChange('en')}>EN</Button>
                    <span>|</span>
                    <Button type="icon" href='#language-zh' className={`lang-link ${activeLanguage === 'zh' ? 'lang-active-link' : ''}`} onClick={() => handleLanguageChange('zh')}>中文</Button>
                </Row>
            </Anchor>
        </Col>
        <Col className="mobile">
            <Button type="icon" onClick={showDrawer} style={{color: 'white', fontSize: '25px'}}>
                ☰
            </Button>
            <Drawer title={<DrawerTitle />} placement="left" onClose={onClose} open={visible} className='drawer' closable={false}>
                <Anchor targetOffset="65" getContainer={() => document.getElementById("anchor-content")} onChange={onClose}>
                  <Link href="#home" title={t.home} />
                  <Link href="#service" title={t.services} />
                  <Link href="#portfolio" title={t.portfolio} />
                  <Link href="#whyus" title={t.advantage} />
                  <Link href="#contact" title={t.contact} />
                  <Row justify='left' align="middle" style={{marginLeft: '10px'}}>
                    <Button type="icon" href='#language-en' className={`lang-link ${activeLanguage === 'en' ? 'lang-active-link' : ''}`} onClick={() => handleLanguageChange('en')}>EN</Button>
                    <span>|</span>
                    <Button type="icon" href='#language-zh' className={`lang-link ${activeLanguage === 'zh' ? 'lang-active-link' : ''}`} onClick={() => handleLanguageChange('zh')}>中文</Button>
                  </Row>
                </Anchor>
            </Drawer>
        </Col>
    </Header>
  )
}

export default Topbar