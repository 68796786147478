import React from 'react';
import './App.css';


import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import Sections from './components/Sections/Sections';
import Footer from './components/Footer/Footer';
import Layout from './components/Layout/Layout';

function App() {
  return (
    <div className="App">
      {/* <Header />
      <Banner />
      <Sections />
      <Footer /> */}

      <Layout /> 
    </div>
  );
}

export default App;
