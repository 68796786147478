import React from 'react'

import { Layout as AntdLayout } from 'antd';
import Home from './Contents/Home';
import Service from './Contents/Service';
import Portfolio from './Contents/Portfolio';
import WhyUs from './Contents/WhyUs';
import Contact from './Contents/Contact';
const { Content } = AntdLayout;

const Contents = ({t ,language}) => {
  const footerStyle = {
    margin: '0 15% 3% 15%',
  }

  return (
    <Content id="anchor-content">
        <div className="main" id="main-content">
            <Home t={t}/>
            <Service t={t}/>
            <Portfolio t={t} language={language}/>
            <WhyUs t={t} />
            <Contact t={t}/>
        </div>
        
        <div id="footer" style={footerStyle}>
          <hr style={{color: '#F6F9FC'}}/>
          <p style={{float: 'left', color: '#9A9A9A'}}>{t.copyright}</p>
        </div>
    </Content>
  )
}

export default Contents