import React from 'react'

import { Col, Row } from 'antd'
import './Home.css';


const Home = ({t}) => {
  return (
    <Row id="home" className="contents" justify="center" align='middle' gutter={[0, 48]}>
        <Col xs={24} md={10} className='home-content'>
            <h1>{t.bannerTitle}</h1>
            <p className='description-1'>{t.bannerDesc1}</p>
            <p className='description-2'>{t.bannerDesc2}</p>
            <button className='banner-btn'>{t.bannerBtnText}</button>
        </Col>
        <Col className="home-image" xs={24} md={10} ></Col>
    </Row>
  )
}

export default Home