import React from 'react'

import { Col, Row } from 'antd'

import './Portfolio.css';
import Logo from '../../../assets/images/direpay-logo.png';

const Portfolio = ({t, language}) => {
  return (
    <>
    <Row id='portfolio' className="contents"  align='middle' justify="space-between">
        <Col className="portfolio-content" xs={24} md={9} >
          <img src={Logo} alt='logo'/>
          <h1>{t.section2Title}</h1>
          <div className="subtitle-2">
            {t.section2Subtitle.part1}
            <span>{t.section2Subtitle.appName}</span>
            {t.section2Subtitle.part2}
            <span>{t.section2Subtitle.charges}</span>
            {t.section2Subtitle.part3}
          </div>
        </Col>
        <Col xs={24} md={12} className="portfolio-image" ></Col>
    </Row>
    <Row id='portfolio-2' className="contents" align='middle'>
          <Row className="portfolio-2-image-content" justify='end'>
              <div className="portfolio-2-desc-content">
                  <h1>
                      {language === 'en' && t.section3Title.part1}
                      {language === 'zh' && <span>{t.section3Title.part1}</span>}
                      <br />
                      <span className="portfolio-2-part-2">
                          {language === 'en' && t.section3Title.part2}
                          {language === 'zh' && t.section3Title.part2}
                      </span>
                  </h1>
                  <div className="subtitle">
                      {t.section3Subtitle} <br />
                      {t.section3Subtitle2}
                  </div>
              </div>
          </Row>
          <div className="portfolio-desc-container">
          
          </div>
    </Row>
    </>
  )
}

export default Portfolio