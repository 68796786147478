export const translations = {
    en: {
        home: "Home",
        services: "Our Services",
        portfolio: "Portfolio",
        advantage: "Why Us",
        contact: "Contact",
        bannerTitle: "ACCEPT PAYMENTS WITH ALL POPULAR PAYMENT METHODS",
        bannerDesc1: "Stability Makes Accepting Payments Easy",
        bannerDesc2: "By Professionals Since 2019",
        bannerBtnText: "Get Started Now",
        onlineBankingTitle: "Online Banking",
        onlineBankingSubtitle: "The most common payment method in South East Asia that allows users to make payment using their own bank credentials",
        bankTransferTitle: "Bank Transfer",
        bankTransferSubtitle: "One of the simplest method to allow users to transfer directly to our Bank Account or scan the QR Code through their own bank application",
        eWalletTitle: "MOBILE E-WALLET",
        eWalletSubtitle: "The most convenient way for mobile users to make payment to you with their local digital wallet",
        cryptoTitle: "CRYPTOCURRENCY",
        cryptoSubtitle: "A decentralised payment method with no restrictions, which is recognised internationally and especially for our younger generation",
        demo: "Demo",
        section2Title: "Helps You Build User Loyalty Through Creative Payment Events Hosted By DirePay",
        section2Subtitle: {
            part1: "All cashback promotions & rewards are fully shouldered by ",
            appName: "Direpay",
            part2: ", with ",
            charges: "ZERO",
            part3: " Charges"
        },
        section3Title: {
            part1: "THE PAYMENT GATEWAY OF CHOICE ",
            part2: "FOR THE WORLD'S BEST"
        },
        section3Subtitle: "DirePay was founded in 2019 with a core focus on providing a payment gateway solution",
        section3Subtitle2: "to support online businesses. Since then, our activities have spread across the industry",
        section4Title: "DIREPAY EMPOWER BUSINESSES TO PROCESS PAYMENTS AT EASE",
        cardTitle1: "Security \n & Privacy",
        cardSubtitle1: "We make sure your funds are kept safe & no issues will arise",
        cardTitle2: "24/7 \n Customer Support",
        cardSubtitle2: "Our professional support team will handle all enquiries on your daily operations",
        cardTitle3: "High \n Acceptance Rate",
        cardSubtitle3: "Our system uses the most efficient processing methods to increase approval rates",
        cardTitle4: "Simple \n Integration",
        cardSubtitle4: "Our API integration is simple and takes 24 hours to complete",
        section5Title: "RUN YOUR BUSINESS WITH",
        nameInput: "Your Name",
        emailInput: "Email",
        companyNameInput: "Company Name",
        companyWebsiteInput: "Company Website",
        contactTypeInput: "Contact Type",
        contactDetailsInput: "Contact Details",
        contactMsg: "How can we help you?",
        contactBtn: "Send",
        copyright: "© Copyright 2020 DirePay | All Rights Reserved",
        nextStep: "Next",
        previousStep: "Previous",
        guide: {
            skip: "Skip",
            back: "Back",
            next: "Next",
            finish: "Finish",
            crypto: {
                page1: "Page One",
                page2: "Page Two",
                step1: "Check the Crypto amount to Deposit",
                step2: "Check the Receiving Amount in Fiat currency",
                step3: "Select the Network of the Cryptocurrency",
                step4: "Next Page",
                step5: "Scan QR Code or Copy Wallet Address and paste into own Crypto Wallet Application",
                step6: "Copy the displayed amount and paste into own crypto wallet application",
                step7: "Proceed to transfer on Crypto Wallet Application",
                step8: "Complete"
            },
            eWallet: {
                step1: "Copy the displayed Wallet Number and paste into own E-Wallet application",
                step2: "Transfer the amount through own E-Wallet application",
                step3: "Input own wallet number",
                step4: "Input the ID of the completed transaction",
                step5: "Upload slip of the completed transaction",
                step6: "Confirm & submit"
            },
            transfer: {
                step1: "Copy Bank Account Number and paste into own banking application",
                step2: "Select the corresponding Bank in own banking application",
                step3: "Copy Amount and Paste into own banking application",
                step4: "Copy reference number and paste into the reference section",
                step5: "Verify if the account name is correct as displayed",
                step6: "Proceed to transfer on banking  application"
            },
            onlineBanking: {
                step1: "Key in Username",
                step2: "Key in Password",
                step3: "Login",
                step4: "Check Transaction Amount",
                step5: "Approve transaction via mobile banking app or key in OTP that was sent via SMS",
                step6: "Payment completed, user will be redirected back to merchant’s website"
            }
        }
    },
    zh: {
        home: "主页",
        services: "服务",
        portfolio: "关于",
        advantage: "优势",
        contact: "联系",
        bannerTitle: "接受所有常用的支付方式",
        bannerDesc1: "稳定性使接受付款变得更容易",
        bannerDesc2: "自2019年起由专业人士提供",
        bannerBtnText: "立即开始",
        onlineBankingTitle: "网络银行",
        onlineBankingSubtitle: "东南亚最常见的支付方式, 允许用户使用自己的银行凭证进行支付",
        bankTransferTitle: "银行转账",
        bankTransferSubtitle: "让用户直接向我们的银行账户转账或通过自己的银行应用程序扫描二维码的最简单方法之一",
        eWalletTitle: "手机电子钱包",
        eWalletSubtitle: "让手机用户使用本地电子钱包向您付款的最便捷方式",
        cryptoTitle: "加密货币",
        cryptoSubtitle: "国际公认尤其也广受年轻一代认可的无限制去中心化支付方法",
        demo: "示范",
        section2Title: "通过泰亚支付举办的创意支付活动帮助您建立用户忠诚度",
        section2Subtitle: {
            part1: "所有返现促销和奖励均由",
            appName: "泰亚支付",
            part2: "支付承担，",
            charges: "零",
            part3: "手续费"
        },
        section3Title: {
            part1: "全球最佳",
            part2: "支付网关的首选"
        },
        section3Subtitle: "泰亚支付成立于2019年，其核心业务是为在线业务提供支付网关解决方案。",
        section3Subtitle2: "即那时起，我们的业务已遍及整个行业",
        section4Title: "泰亚支付帮助企业轻松处理支付业务",
        cardTitle1: "安全与隐私",
        cardSubtitle1: "我们确保您的资金安全，不会产生任何问题",
        cardTitle2: "全天候客服",
        cardSubtitle2: "我们的专业团队将为您处理有关日常运营的所有问题",
        cardTitle3: "接受率高",
        cardSubtitle3: "我们的系统采用最高效的处理方法来提高审批率",
        cardTitle4: "简单的集成",
        cardSubtitle4: "我们的应用程序接口集成非常简单，只需24小时即可完成",
        section5Title: "开展业务就使用",
        nameInput: "您的名字",
        emailInput: "邮箱",
        companyNameInput: "公司名称",
        companyWebsiteInput: "公司网址",
        contactTypeInput: "联系方式",
        contactDetailsInput: "联系资讯",
        contactMsg: "我们能为您提供哪些帮助？",
        contactBtn: "发送",
        copyright: "© 2020 泰亚支付 | 版权所有",
        nextStep: "下一步",
        previousStep: "上一步",
        guide: {
            skip: "跳过",
            back: "返回",
            next: "下一步",
            finish: "完成",
            crypto: {
                page1: "第一页",
                page2: "第二页",
                step1: "检查要存入的加密货币数量",
                step2: "查看法定货币中的接收金额",
                step3: "选择加密货币的网络",
                step4: "下一页",
                step5: "扫描二维码或复制钱包地址并粘贴到自己的加密钱包应用程序",
                step6: "复制显示的金额并粘贴到自己的加密钱包应用程序",
                step7: "在加密钱包应用程序上进行转账",
                step8: "完成"
            },
            eWallet: {
                step1: "复制显示的钱包号并粘贴到自己的电子钱包应用程序",
                step2: "通过自己的电子钱包应用程序转账",
                step3: "输入自己的钱包号",
                step4: "输入完成交易的ID",
                step5: "上传完成交易的凭据",
                step6: "确认并提交"
            },
            transfer: {
                step1: "复制银行账号并粘贴到自己的银行应用程序",
                step2: "在自己的银行应用程序中选择相应的银行",
                step3: "复制金额并粘贴到自己的银行应用程序",
                step4: "复制参考号并粘贴到参考部分",
                step5: "验证账户名是否正确显示",
                step6: "在银行应用程序上进行转账"
            },
            onlineBanking: {
                step1: "输入用户名",
                step2: "输入密码",
                step3: "登录",
                step4: "检查交易金额",
                step5: "通过手机银行应用程序批准交易或输入通过短信发送的OTP",
                step6: "付款完成，用户将被重定向回商家的网站"
            }
        }        
    }
};