import React, { useEffect, useState } from 'react'
import Banner from './../../../../../assets/images/service-demo/transfer.svg'
import Joyride from 'react-joyride';
const GuideTransfer = ({t, handleJoyrideCallback, handleImageLoad}) => {
  const [run, setRun] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRun(true)
    }, 500)
  })

  const stepSettings = {
    disableBeacon: true,
    spotlightPadding: 0,
    floaterProps: {
      disableAnimation: true,
    },
    locale : {
      skip: t.guide.skip,
      back: t.guide.back,
      next: t.guide.next,
      last: t.guide.finish
    }
  }

  const steps = [
    {
      content: <div>1. {t.guide.transfer.step1}</div>,
      target: '.focus-step-transfer-0',
      ...stepSettings
    },
    {
      content: <div>2. {t.guide.transfer.step2}</div>,
      target: '.focus-step-transfer-1',
      ...stepSettings
    },
    {
      content: <div>3. {t.guide.transfer.step3}</div>,
      target: '.focus-step-transfer-2',
      ...stepSettings
    },
    {
      content: <div>4. {t.guide.transfer.step4}</div>,
      target: '.focus-step-transfer-3',
      ...stepSettings
    },
    {
      content: <div>5. {t.guide.transfer.step5}</div>,
      target: '.focus-step-transfer-4',
      ...stepSettings
    },
    {
      content: <div>6. {t.guide.transfer.step6}</div>,
      target: '.focus-step-transfer-5',
      placement: 'center',
      ...stepSettings
    },
  ]

  const bannerStyle = {
    width: '100%',
    height: 'auto'
  }

  return (
    <div className='container'>
      <Joyride
        callback={handleJoyrideCallback}
        steps={steps}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <img className='banner' src={Banner} onLoad={handleImageLoad} style={bannerStyle}/>
      {Array.from({ length: 6 }).map((_, index) => (
        <div className={`focus-step-transfer-${index}`} key={index}></div>
      ))}
    </div>
  )
}

export default GuideTransfer