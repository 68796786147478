import { Modal } from 'antd'
import React, { useState } from 'react'
import GuideOnlineBanking from './ServiceGuide/OnlineBanking'
import GuideTransfer from './ServiceGuide/Transfer'
import GuideEWallet from './ServiceGuide/EWallet'
import GuideCrypto from './ServiceGuide/Crypto'
import { STATUS } from 'react-joyride';
const ServiceDemoModal = ({visible, content, onCancel, t}) => {
  const [modalVisible, setModalVisible] = useState(visible);
  const [currentStep, setCurrentStep] = useState(0);
  const [modalWidth, setModalWidth] = useState('0%');

  const handleJoyrideCallback = (data) => {
    const { status, index } = data;
    setCurrentStep(index);
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setModalVisible(false);
    }
  };

  const handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    const { innerWidth, innerHeight } = window;
    const ratio = naturalWidth / naturalHeight;
    // console.log(naturalWidth, naturalHeight, innerWidth, innerHeight, ratio);

    if (innerWidth > innerHeight) { // desktop (horizontal)
      setModalWidth(`calc((100vh - 40px) * ${ratio})`);
    } else { // mobile (vertical)
      setModalWidth('100%');
    }
  };

  let Component = <></>
  switch (content) {
    case 'online-banking':
      Component = <GuideOnlineBanking t={t} handleJoyrideCallback={handleJoyrideCallback} handleImageLoad={handleImageLoad} currentStep={currentStep} />
      break;
    case 'transfer':
      Component = <GuideTransfer t={t} handleJoyrideCallback={handleJoyrideCallback} handleImageLoad={handleImageLoad} />
      break;
    case 'e-wallet':
      Component = <GuideEWallet t={t} handleJoyrideCallback={handleJoyrideCallback} handleImageLoad={handleImageLoad} />
      break;
    case 'crypto':
      Component = <GuideCrypto t={t} handleJoyrideCallback={handleJoyrideCallback} handleImageLoad={handleImageLoad} currentStep={currentStep} />
      break;
    default:
      break;
  }

  return (
    <Modal 
      open={modalVisible}
      onCancel={onCancel} 
      footer={null}
      width={modalWidth}
      className='service-demo-modal'
      >
      {Component}
    </Modal>
  )
}

export default ServiceDemoModal