import React, { useEffect, useState } from 'react'
import Banner1 from './../../../../../assets/images/service-demo/online-banking-1.svg'
import Banner2 from './../../../../../assets/images/service-demo/online-banking-2.svg'
import Banner3 from './../../../../../assets/images/service-demo/online-banking-3.svg'
import Banner4 from './../../../../../assets/images/service-demo/online-banking-4.svg'
import Joyride from 'react-joyride';
const GuideOnlineBanking = ({t, handleJoyrideCallback, handleImageLoad, currentStep}) => {
  const [run, setRun] = useState(false);
  const [banner, setBanner] = useState(Banner1);

  useEffect(() => {
    setTimeout(() => {
      setRun(true)
    }, 500)
  })

  useEffect(() => {
    switch (currentStep) {
      case 3:
        setBanner(Banner2);
        break;
      case 4:
        setBanner(Banner3);
        break;
      case 5:
        setBanner(Banner4);
        break;
      default:
        setBanner(Banner1);
    }
  }, [currentStep])

  const stepSettings = {
    disableBeacon: true,
    spotlightPadding: 0,
    floaterProps: {
      disableAnimation: true,
    },
    locale : {
      skip: t.guide.skip,
      back: t.guide.back,
      next: t.guide.next,
      last: t.guide.finish
    }
  }

  const steps = [
    {
      content: <div>1. {t.guide.onlineBanking.step1}</div>,
      target: '.focus-step-online-banking-0',
      ...stepSettings
    },
    {
      content: <div>2. {t.guide.onlineBanking.step2}</div>,
      target: '.focus-step-online-banking-1',
      ...stepSettings
    },
    {
      content: <div>3. {t.guide.onlineBanking.step3}</div>,
      target: '.focus-step-online-banking-2',
      ...stepSettings
    },
    {
      content: <div>4. {t.guide.onlineBanking.step4}</div>,
      target: '.focus-step-online-banking-3',
      ...stepSettings
    },
    {
      content: <div>5. {t.guide.onlineBanking.step5}</div>,
      target: '.focus-step-online-banking-4',
      ...stepSettings
    },
    {
      content: <div>6. {t.guide.onlineBanking.step6}</div>,
      target: '.focus-step-online-banking-5',
      ...stepSettings
    },
  ]

  const bannerStyle = {
    width: '100%',
    height: 'auto'
  }
  
  return (
    <div className='container'>
      <Joyride
        callback={handleJoyrideCallback}
        steps={steps}
        continuous
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <img className='banner' src={banner} onLoad={handleImageLoad} style={bannerStyle}/>
      {Array.from({ length: 6 }).map((_, index) => (
        <div className={`focus-step-online-banking-${index}`} key={index}></div>
      ))}
    </div>
  );
}

export default GuideOnlineBanking