import React from 'react'
import { Layout as AntdLayout } from 'antd';

import Topbar from './Topbar';
import Contents from './Contents';
import { useTranslation } from '../../TranslationContext';

const Layout = () => {
  const { t, language } = useTranslation();
  return (
    <AntdLayout>
      <Topbar t={t} />
      <Contents t={t} language={language}/>
    </AntdLayout>
  )
}

export default Layout